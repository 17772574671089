<template>
  <PageLayout>
    <div class="cookies-page">
      <h1 class="header">Cookies</h1>
        <div class="COOKIES">
          <h6 class=" title-header">COOKIES</h6>
          <p class="title">To make this site work properly, we sometimes place cookies on your device. A 
cookie is a small text file that a website saves on your computer or mobile device 
when you visit the site. It enables the website to remember your actions and 
preferences (such as login, language, font size and other display preferences) 
over a period of time, so you don’t have to keep re-entering them whenever you 
come back to the site or browse from one page to another. 
         </p>
        </div>
        <div class="HOW-DO-WE-USE-COOKIES">
          <h6 class=" title-header">HOW DO WE USE COOKIES?</h6>
          <p class="title">We use cookies to collect information about users so that we can tailor your user 
experience, provide the best possible functionality and develop our services. We 
can use both first-party and third-party cookies. Cookies are used among others 
to link social media functions and for online behavioural advertising.
              
          </p>
        </div>
        <div class="HOW-TO-CONTROL-COOKIES">
          <h6 class=" title-header">HOW TO CONTROL COOKIES?</h6>
          <p class="title">Consent is considered given if the browser is set to accept the use of cookies. 
This also applies if the browser is pre-set for acceptance.
         </p>
         <p class="title">You can control cookies as you wish – for details, see aboutcookies.org. You can 
delete all cookies that are already on your computer and you can set most 
browsers to prevent them from being placed. If you do this, however, you may 
have to manually adjust some preferences every time you visit a site and some 
services and functionalities may not work.
         </p>
         <p class="title"><a href="" class="link">Edit cookie preferences</a></p>
        
        </div>
    </div>
  </PageLayout>
</template>

<script>
import PageLayout from "@/layouts/PageLayout";

export default {
  name: "CookiesPage",
  components: {
    PageLayout
  }
};
</script>

<style lang="scss" scoped>
.cookies-page {
  top:0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  
  .header{
    font-weight: bold;
    width:100%;
    font-size:57px;
  }
  
}
.cookies-page{
  .title-header{
      margin-top:30px;
      font-weight: bold;
    }
    .title{
      margin-top:20px;
      white-space: pre;
      text-align: left;
      margin: 20px 0px 16px;
      .link{
        color: red;
      }
  }
}
// .privacy-page .data-controller {
//     // line-height : normal;
//     .title-header{
//       margin-top:30px;
//       font-weight: bold;
//     }
//     .title{
//       margin-top:20px;
//       white-space: pre;
//       text-align: left;
//       margin: 20px 0px 16px;
//   }
// }
</style>
